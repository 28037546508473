import React, { useState, useEffect } from "react";
import { makeStyles, Box } from "@material-ui/core";
import { TextInput, Button } from "react-admin";
import { Typography } from "@material-ui/core";
import { useField } from "react-final-form";
import IconContentAdd from "@material-ui/icons/Add";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import ImageDefault from "../../assets/img/note-default.png";
import DeleteIcon from "@material-ui/icons/Delete";
import { useTranslate } from "ra-core";
import CenterFocus from "@material-ui/icons/CenterFocusWeak";
import { FocalPoint } from "./FocalPoint";
import medioData from "Static/data/medio.json";

const useStyles = makeStyles({
  container: {
    "& img": {
      width: "100%",
      height: "250px",
      objectFit: "cover",
    },
    "& button:hover + img": {
      filter: "blur(3px) brightness(.9)",
    },
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  hidden: {
    display: "none",
  },
  photographer: {
    display: "flex",
    justifyContent: "flex-end",
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "12px",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: "400",
    letterSpacing: "0.03333em",
    marginBottom: "10px",
  },
  deleteButton: {
    lineHeight: 1,
    color: "rgb(31, 58, 79)",
    background: "rgba(255, 255, 255, 0.8)",
    boxShadow: "1px 1px 3px 1px #0000002b",
    borderRadius: "50px",
    fontSize: "12px",
    minWidth: "28px",
    width: "28px",
    height: "28px",
    "& .MuiSvgIcon-root": {
      width: "18px",
      height: "18px",
    },
    "&:hover": {
      background: "rgba(255, 255, 255, 0.8)",
    },
  },
});

export const ImageFeatured = ({ record }) => {
  const [openCropper, setOpenCropper] = useState(false);
  const [featuredImage, setFeaturedImage] = useState("");
  const [photographer, setPhotographer] = useState("");
  const classes = useStyles();
  const [customEvent, setCustomEvent] = useState();
  const { input: input_featured_image } = useField("featured_image");
  const { input: input_epigrafe } = useField("epigrafe");
  const { input: input_photographer } = useField("photographer");

  const translate = useTranslate();

  useEffect(() => {
    if (record) {
      setFeaturedImage(record.featured_image);
      setPhotographer(record.photographer);
    }
  }, [record]);

  const handleCallback = (event) => {
    const { editorId, image } = event.detail;
    if (editorId === "featuredImage") {
      setFeaturedImage(image.imageUrl);
      input_featured_image.onChange(image.imageUrl);
      if (image.caption) {
        input_epigrafe.onChange(image.caption);
      }
      if (image.photographer && image.photographer.name) {
        setPhotographer(image.photographer.name);
        input_photographer.onChange(image.photographer.name);
      } else {
        setPhotographer("");
      }
    }
  };

  const handleOnChange = (e) => {
    setFeaturedImage(e.target.value);
  };

  const handleClick = () => {
    window.addEventListener("selected-image-imagepicker", handleCallback);
    window.dispatchEvent(customEvent);
  };

  const handleFocus = () => {
    setOpenCropper(true);
  };

  const handleDelete = () => {
    setFeaturedImage(null);
    input_featured_image.onChange(null);
  };

  const getPhotographer = () => {
    if (photographer && photographer !== "") {
      return <span className={classes.photographer}>PH: {photographer}</span>;
    }
    return null;
  };

  useEffect(() => {
    setCustomEvent(
      new CustomEvent("open-imagepicker", {
        detail: {
          editor: "featuredImage",
        },
        bubbles: false,
        cancelable: true,
        composed: false,
      })
    );
    return () => {
      setCustomEvent(null);
    };
  }, []);

  return (
    <>
      <div className="d-flex">
        <Typography variant="h6" gutterBottom>
          {translate("components.main_image")}
        </Typography>
      </div>
      <div className={classes.container} style={{ position: "relative" }}>
        {featuredImage && featuredImage !== "" ? (
          <>
            <Box
              style={{
                position: "absolute",
                top: "12px",
                padding: "0 12px",
                boxSizing: "border-box",
                zIndex: 2,
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Button
                onClick={handleClick}
                label={translate("components.update")}
                style={{
                  color: "rgb(31, 58, 79)",
                  background: "rgba(255, 255, 255, 0.8)",
                  boxShadow: "1px 1px 3px 1px #0000002b",
                }}
              >
                <AutorenewIcon />
              </Button>
              <Button onClick={handleDelete} className={classes.deleteButton}>
                <DeleteIcon />
              </Button>
            </Box>
            {medioData?.show_focal_point ? (
              <Box
                style={{
                  position: "absolute",
                  bottom: "12px",
                  padding: "0 12px",
                  boxSizing: "border-box",
                  zIndex: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Button
                  onClick={handleFocus}
                  label={translate("components.focal_point")}
                  style={{
                    color: "rgb(31, 58, 79)",
                    background: "rgba(255, 255, 255, 0.8)",
                    boxShadow: "1px 1px 3px 1px #0000002b",
                  }}
                >
                  <CenterFocus />
                </Button>
              </Box>
            ) : null}
          </>
        ) : (
          <Button
            onClick={handleClick}
            label={translate("components.add")}
            style={{
              position: "absolute",
              right: "12px",
              top: "12px",
              zIndex: 2,
              color: "rgb(31, 58, 79)",
              background: "rgba(255, 255, 255, 0.8)",
              boxShadow: "1px 1px 3px 1px #0000002b",
            }}
          >
            <IconContentAdd />
          </Button>
        )}
        <img
          src={featuredImage ? featuredImage : ImageDefault}
          alt=""
          className={classes.img}
        />
      </div>
      {medioData?.show_focal_point ? (
        <FocalPoint
          record={record}
          openCropper={openCropper}
          setOpenCropper={setOpenCropper}
          featured_image_src={featuredImage}
        />
      ) : null}
      {getPhotographer()}
      <div className={classes.hidden}>
        <TextInput
          id="featured_image"
          source="featured_image"
          label={translate("components.main_image")}
          onChange={handleOnChange}
          value={featuredImage}
          fullWidth
        />
      </div>
    </>
  );
};
