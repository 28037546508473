import { useTranslate } from "ra-core";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import Cropper from "react-easy-crop";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { useState, useCallback, useEffect } from "react";
import { useField } from "react-final-form";
import DeleteIcon from "@material-ui/icons/Delete";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((_theme) => ({
  dialog: {
    zIndex: 1,
    backgroundColor: "white",
  },
  helperText: {
    "& .MuiFormHelperText-root": {
      maxWidth: "490px",
    },
  },
  deleteButton: {
    color: "red",
    border: "1px solid red",
  },
}));

export const FocalPoint = ({
  record,
  openCropper,
  setOpenCropper,
  featured_image_src,
}) => {
  const { input: input_cropped_image } = useField("focal_point_image");
  const translate = useTranslate();
  const classes = useStyles();

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [state, setState] = useState();
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(false);
  const [imageSrc, setImageSrc] = useState(record?.featured_image);

  const onCropComplete = useCallback(
    (_, croppedAreaPixels) => {
      setCroppedAreaPixels({ vertical: croppedAreaPixels });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [featured_image_src]
  );

  const handleApplyCrop = () => {
    input_cropped_image.onChange(croppedAreaPixels);
    setState(JSON.stringify(croppedAreaPixels));
    setOpenCropper(false);
  };

  const handleDelete = () => {
    setCroppedAreaPixels(null);
    input_cropped_image.onChange(null);
    setState(null);
  };

  useEffect(() => {
    if (record) {
      if (record.featured_image) {
        setImageSrc(record.featured_image);
      }
      if (record.focal_point_image) {
        setCroppedAreaPixels(record.focal_point_image);
        input_cropped_image.onChange(record.focal_point_image);
        setState(JSON.stringify(record.focal_point_image));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  useEffect(() => {
    if (featured_image_src && featured_image_src !== imageSrc) {
      setImageSrc(featured_image_src);
      input_cropped_image.onChange(null);
      setState(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featured_image_src]);

  return (
    <>
      {input_cropped_image?.value ? (
        <Box
          style={{
            display: "flex",
            alignItems: "baseline",
            width: "100%",
            gap: "10px",
          }}
        >
          <TextField
            id="focal_point_image"
            source="focal_point_image"
            label={translate("components.focal_point")}
            disabled
            fullWidth
            margin="dense"
            variant="filled"
            value={state}
          />
          <Button onClick={handleDelete} className={classes.deleteButton}>
            <DeleteIcon />
          </Button>
        </Box>
      ) : null}
      <Dialog open={openCropper} onClose={() => setOpenCropper(false)}>
        <DialogTitle className={classnames(classes.dialog)}>
          {translate("resources.media.fields.title_crop")}
        </DialogTitle>
        <DialogContent
          style={{ height: "500px", width: "500px", overflow: "hidden" }}
        >
          {imageSrc ? (
            <Cropper
              image={imageSrc}
              crop={crop}
              aspect={3 / 4}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
            />
          ) : null}
        </DialogContent>
        <DialogActions className={classnames(classes.dialog)}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setOpenCropper(false)}
          >
            {translate("resources.media.fields.discard_crop")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleApplyCrop(false)}
          >
            {translate("resources.media.fields.apply_crop")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
